import utility from '@/common/utility'
import auth from '@/common/auth'

export default class FinancialEstimateRuleModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.financialEstimateRuleGuid
      this.financialEstimateRuleGuid = props.financialEstimateRuleGuid
      this.meterageListGuid = props.meterageListGuid
      this.surplusAmount = props.surplusAmount
      this.financialEstimateGuid = props.financialEstimateGuid
      this.amount = props.amount
      this.financialEstimateRulePercent = props.financialEstimateRulePercent
      this.tenderGuid = props.tenderGuid
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.isEffective = props.isEffective
      this.staffName = props.staffName
      this.num = props.num
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.financialEstimateRuleGuid) this.financialEstimateRuleGuid = ''
    if (!this.meterageListGuid) this.meterageListGuid = ''
    if (!this.surplusAmount) this.surplusAmount = 0
    if (!this.financialEstimateGuid) this.financialEstimateGuid = ''
    if (!this.amount) this.amount = 0
    if (!this.financialEstimateRulePercent) this.financialEstimateRulePercent = 0
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.isEffective) this.isEffective = 0
    if (!this.staffName) this.staffName = ''
    if (!this.num) this.num = 0
  }

  generatePrimaryKey () {
    this.financialEstimateRuleGuid = utility.getUuid()
    this.resourceId = this.financialEstimateRuleGuid
  }
}
