<template>
  <div class="financial-estimate-rule-form">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item
          v-if="changeDirectives.length"
          >
          <el-select
            v-model="meterageListType"
            placeholder="清单类型"
            clearable>
            <el-option
              v-for="changeDirective in changeDirectives"
              :key="changeDirective.changeDirectiveGuid"
              :label="changeDirective.directiveName"
              :value="changeDirective.directive">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleLoadClick">加载</el-button>
          <el-button type="primary" @click="handleSaveClick">保存</el-button>
          <el-button type="primary" @click="handleDispatchClick">摊销</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <div
      v-if="defaultDataList.length"
      class="spread-wrapper">
      <my-spread
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        @value-changed="handleValueChanged"
        >
      </my-spread>
    </div>

    <my-dialog ref="formDialog"
      title="分销"
      ok-text="确定"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <el-form>
        <el-form-item>
          <el-tree
            ref="myTree"
            :data="treeNodes"
            :props="{ label: 'financialEstimateName' }"
            show-checkbox
            >
          </el-tree>
        </el-form-item>
      </el-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import SpreadInfo from '@/views/FinancialEstimateRuleManage/FinancialEstimateRuleNewSpreadInfo'
import MySpread from '@/components/Spread/MySpread'
import financialEstimateService from '@/services/financialEstimateService'
import financialEstimateRuleService from '@/services/financialEstimateRuleService'
import FinancialEstimateRuleModel from '@/model/FinancialEstimateRuleModel'
import auth from '@/common/auth'
import utility from '@/common/utility'
import changeDirectiveService from '@/services/changeDirectiveService'
import PageInfo from '@/support/PageInfo'

export default {
  name: 'FinancialEstimateRuleFormNew',
  components: {
    MySearchBar,
    MyDialog,
    MySpread
  },
  data () {
    return {
      defaultDataList: [],
      spreadInfo: JSON.parse(JSON.stringify(SpreadInfo)),
      columns: [],
      treeNodes: [],
      financialEstimateRuleList: [],
      financialEstimateMap: {},
      currentRowIndex: 0,
      meterageListType: 'ALL',
      changeDirectives: []
    }
  },
  methods: {
    handleLoadClick () {
      this.getColumns()
    },
    handleValueChanged (rowIndex, dataKey) {
      let row = this.$refs.mySpread.getRow(rowIndex)
      let cellValue = this.$refs.mySpread.getValue(rowIndex, dataKey)
      let ending = dataKey.substr(dataKey.length - 1)
      let mainKey = dataKey.substr(0, dataKey.length - 1)
      if (ending === 'B') {
        let value = Math.round(utility.floatDivide(utility.floatMultiple(row.DeclareAmount, cellValue), 100))
        this.$refs.mySpread.setValue(rowIndex, mainKey + 'J', value)
        row[mainKey + 'J'] = value
      } else if (ending === 'N') {
        let value = Math.round(utility.floatMultiple(cellValue, row.Price))
        this.$refs.mySpread.setValue(rowIndex, mainKey + 'J', value)
        row[mainKey + 'J'] = value
      }

      row[dataKey] = cellValue
      let newRow = this.$refs.mySpread.getRow(rowIndex)
      let totalAmount = this.getTotalAmount(newRow)
      this.$refs.mySpread.setValue(rowIndex, 'TotalAmount', totalAmount)
    },
    handleDispatchClick () {
      this.$refs.formDialog.open()
      this.currentRowIndex = this.$refs.mySpread.getActiveRowIndex()
    },
    handleOkClick () {
      this.$myLoading()
      // 清理数据
      this.treeNodes.forEach(node => {
        if (node.children && node.children.length) {
          node.children.forEach(childNode => {
            this.$refs.mySpread.setValue(this.currentRowIndex, childNode.financialEstimateCode + 'B', '')
            this.$refs.mySpread.setValue(this.currentRowIndex, childNode.financialEstimateCode + 'N', '')
            this.$refs.mySpread.setValue(this.currentRowIndex, childNode.financialEstimateCode + 'J', '')
          })
        } else {
          this.$refs.mySpread.setValue(this.currentRowIndex, node.financialEstimateCode + 'B', '')
          this.$refs.mySpread.setValue(this.currentRowIndex, node.financialEstimateCode + 'N', '')
          this.$refs.mySpread.setValue(this.currentRowIndex, node.financialEstimateCode + 'J', '')
        }
      })
      let selectedNodes = this.$refs.myTree.getCheckedNodes(false, true)
        .filter(node => !node.children)
      let totalAmount = 0

      let totalDefaultAmount = 0
      selectedNodes.forEach(node => {
        totalDefaultAmount = utility.floatAdd(totalDefaultAmount, node.financialEstimateAmount)
      })

      selectedNodes.forEach(node => {
        let percent = utility.floatDivide(node.financialEstimateAmount * 100, totalDefaultAmount).toFixed(2)
        let row = this.$refs.mySpread.getRow(this.currentRowIndex)
        let num = utility.floatDivide(utility.floatMultiple(percent, row.DeclareNum), 100).toFixed(2)
        let amount = Math.round(utility.floatDivide(utility.floatMultiple(percent, row.DeclareAmount), 100))
        this.$refs.mySpread.setValue(this.currentRowIndex, node.financialEstimateCode + 'B', percent)
        this.$refs.mySpread.setValue(this.currentRowIndex, node.financialEstimateCode + 'N', num)
        this.$refs.mySpread.setValue(this.currentRowIndex, node.financialEstimateCode + 'J', amount)

        totalAmount += amount
      })

      this.$refs.mySpread.setValue(this.currentRowIndex, 'TotalAmount', totalAmount)
      this.$refs.formDialog.close()
      this.$loading().close()
    },
    handleSaveClick () {
      // 检查是否有错误
      this.$refs.mySpread.validate()
      if (this.$refs.mySpread.errorKeys.length === 0) {
        this.$myLoading()
        this.financialEstimateRuleList = []
        this.$refs.mySpread.getData().forEach((item, index) => {
          let map = {}
          for (let key of Object.keys(item)) {
            let ending = key.substr(key.length - 1)
            let mainKey = key.substr(0, key.length - 1)
            if (ending === 'B' || ending === 'N' || ending === 'J') {
              if (!map[mainKey]) {
                map[mainKey] = {}
              }
              map[mainKey][ending] = item[key]
            }
          }

          const meterageListGuid = this.defaultDataList[index].MeterageListGuid
          for (let mapKey of Object.keys(map)) {
            let financialEstimateRuleModel = new FinancialEstimateRuleModel({
              meterageListGuid: meterageListGuid,
              surplusAmount: parseFloat(item.DeclareAmount),
              financialEstimateGuid: this.financialEstimateMap[mapKey],
              amount: parseFloat(map[mapKey].J),
              financialEstimateRulePercent: parseFloat(map[mapKey].B),
              num: parseFloat(map[mapKey].N),
              tenderGuid: auth.getUserInfo().tenderGuid,
              isEffective: 1
            })
            if (financialEstimateRuleModel.amount !== 0) {
              this.financialEstimateRuleList.push(financialEstimateRuleModel)
            }
          }
        })

        financialEstimateRuleService.batchAdd(this.financialEstimateRuleList, this.meterageListType)
          .then(res => {
            if (res.data.code === 1) {
              this.$message({
                type: 'success',
                message: '操作成功！'
              })
              this.$loading().close()
              this.$router.push('/financialEstimateRule')
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
              this.$loading().close()
            }
          })
          .catch(err => {
            console.log(err)
            this.$loading().close()
            this.$message({
              type: 'error',
              message: '操作失败！'
            })
          })
      } else {
        this.$message({
          type: 'error',
          message: '校验失败，请检查您的输入！'
        })
      }
    },
    getColumns () {
      this.defaultDataList = []
      financialEstimateService.list({}, { projectGuid: auth.getUserInfo().projectGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.spreadInfo = JSON.parse(JSON.stringify(SpreadInfo))
            this.spreadInfo.columns.push({
              label: '合计分配金额',
              dataKey: 'TotalAmount',
              width: '100',
              validator (row) {
                if (row.TotalAmount !== undefined && row.TotalAmount !== null && row.TotalAmount !== '') {
                  if (parseFloat(row.TotalAmount) !== parseFloat(row.DeclareAmount)) {
                    return false
                  }
                }
                return true
              }
            })
            this.treeNodes = JSON.parse(JSON.stringify(res.data.data))
            this.columns = res.data.data
            this.columns.forEach(column => {
              this.financialEstimateMap[column.financialEstimateCode] = column.financialEstimateGuid
              column.label = column.financialEstimateName
              if (column.children && column.children.length) {
                column.children = column.children.map(childColumn => {
                  this.financialEstimateMap[childColumn.financialEstimateCode] = childColumn.financialEstimateGuid
                  childColumn.label = childColumn.financialEstimateName
                  childColumn.children = [
                    {
                      label: '百分比',
                      dataKey: `${childColumn.financialEstimateCode}B`,
                      editable: true,
                      width: 0
                    },
                    {
                      label: '数量',
                      dataKey: `${childColumn.financialEstimateCode}N`,
                      editable: true,
                      width: 90
                    },
                    {
                      label: '金额',
                      dataKey: `${childColumn.financialEstimateCode}J`,
                      editable: true,
                      width: 90
                    }
                  ]
                  return childColumn
                })
              } else {
                column.children = [
                  {
                    label: '百分比',
                    dataKey: `${column.financialEstimateCode}B`,
                    editable: true,
                    width: 0
                  },
                  {
                    label: '数量',
                    dataKey: `${column.financialEstimateCode}N`,
                    editable: true,
                    width: 90
                  },
                  {
                    label: '金额',
                    dataKey: `${column.financialEstimateCode}J`,
                    editable: true,
                    width: 90
                  }
                ]
              }
              this.spreadInfo.columns.push(column)
            })

            this.getDefaultDataList()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败，请重试！'
          })
        })
    },
    getChangeDirectives () {
      changeDirectiveService.list(new PageInfo({ pageIndex: 0, pageSize: 999 }), { tenderGuid: auth.getUserInfo().tenderGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.changeDirectives = res.data.data
            this.changeDirectives.unshift({
              changeDirectiveGuid: 'ZC',
              directive: 'ZC',
              directiveName: '清单'
            })
            this.changeDirectives.unshift({
              changeDirectiveGuid: 'ALL',
              directive: 'ALL',
              directiveName: '全部'
            })
            this.getColumns()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败，请重试！'
          })
        })
    },
    getDefaultDataList () {
      financialEstimateRuleService.getMeterageList(auth.getUserInfo().projectGuid, auth.getUserInfo().tenderGuid, this.meterageListType)
        .then(res => {
          if (res.data.code === 1) {
            this.defaultDataList = res.data.data.map(row => {
              row.TotalAmount = this.getTotalAmount(row)
              return row
            })
            this.$nextTick(() => {
              this.$refs.mySpread.frozenColumns(6)
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败，请重试！'
          })
        })
    },
    getTotalAmount (row) {
      let TotalAmount = 0
      for (let key of Object.keys(row)) {
        if (key.substr(key.length - 1) === 'J' && row[key] !== '' && !isNaN(row[key])) {
          let value = row[key] === null || row[key] === '' ? 0 : row[key]
          TotalAmount += parseFloat(value)
        }
      }
      return TotalAmount
    }
  },
  mounted () {
    this.getChangeDirectives()
  }
}
</script>

<style scoped lang="scss">
.financial-estimate-rule-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
