import BaseService from '@/services/BaseService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'financialestimaterule'
let getMeterageListUrl = 'GetMeterageListDeclareAmount'
let batchAddUrl = 'BatchAddFinancialEstimateRules'
let getEstimatesUrl = 'GetEstimates'
let getTenderEstimatesUrl = 'GetTenderEstimates'

class FinancialEstimateRuleService extends BaseService {
  getMeterageList (projectGuid, tenderGuid, meterageListType) {
    return axios.get(utility.getFullUrl(this.resourceName, getMeterageListUrl), {
      params: {
        projectGuid,
        tenderGuid,
        meterageListType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getTenderEstimates (projectGuid, tenderGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, getTenderEstimatesUrl), {
      params: {
        projectGuid,
        tenderGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getEstimates (projectGuid) {
    return axios.get(utility.getFullUrl(this.resourceName, getEstimatesUrl), {
      params: {
        projectGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  batchAdd (financialEstimateRuleList, meterageListType) {
    financialEstimateRuleList.forEach(financialEstimateRuleModel => {
      financialEstimateRuleModel.generatePrimaryKey()
    })
    return axios.post(utility.getFullUrl(this.resourceName, batchAddUrl), financialEstimateRuleList, {
      params: {
        meterageListType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const financialEstimateRuleService = new FinancialEstimateRuleService(resourceName)

export default financialEstimateRuleService
