import BaseService from '@/services/BaseService'

let resourceName = 'changedirective'

class ChangeDirectiveService extends BaseService {
}

const changeDirectiveService = new ChangeDirectiveService(resourceName)

export default changeDirectiveService
