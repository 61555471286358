import BaseService from '@/services/BaseService'

let resourceName = 'financialEstimate'

class FinancialEstimateService extends BaseService {
}

const financialEstimateService = new FinancialEstimateService(resourceName)

export default financialEstimateService
