const FinancialEstimateRuleNewSpreadInfo = {
  sheetName: '概算规则',
  columns: [
    {
      label: '子目号',
      dataKey: 'MeterageDisplayCode',
      width: '110'
    },
    {
      label: '子目名称',
      dataKey: 'MeterageName',
      width: '280'
    },
    {
      label: '单位',
      dataKey: 'Unit',
      width: '60'
    },
    {
      label: '合同数量',
      dataKey: 'Num',
      width: '100'
    },
    {
      label: '合同单价',
      dataKey: 'Price',
      width: '100'
    },
    {
      label: '合同金额',
      dataKey: 'Amount',
      width: '100'
    },
    {
      label: '剩余数量',
      dataKey: 'DeclareNum',
      width: 100
    },
    {
      label: '剩余金额',
      dataKey: 'DeclareAmount',
      width: '100'
    }
  ]
}

export default FinancialEstimateRuleNewSpreadInfo
